import Icon from '@xo-union/tk-component-icons';
import { Img } from '@xo-union/tk-component-picture';
import { Body2, H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React from 'react';
import type { Decorated } from 'types/vendor';
import Styles from './TeamInfo.scss';

interface AvatarProps {
	url: string;
	size?: number;
}

const Avatar = ({ url, size }: AvatarProps) => {
	return (
		<Img
			src={`${url}~sc_${size}.${size}`}
			className={Styles.img}
			alt="meet the owner"
		/>
	);
};

interface VendorInfoProps {
	bio: Decorated['bio'];
	isInLiteRFQ?: boolean;
	includeQuickResponderBadge?: boolean;
	vendorName?: string;
}

export const VendorInfo = ({
	bio,
	isInLiteRFQ = false,
	includeQuickResponderBadge = false,
	vendorName,
}: VendorInfoProps) => {
	const { name, displayRole, photo } = bio || {};

	if (!name && !displayRole) {
		return null;
	}

	return (
		<div
			className={classNames(Styles.vendorInfoWrapper, {
				[Styles.isInLiteRFQ]: isInLiteRFQ,
			})}
		>
			{photo?.url && <Avatar url={photo.url} size={90} />}
			<div className={Styles.intro}>
				<div className={Styles.name}>{vendorName}</div>
				<div className={Styles.nameAndRole}>
					<Body2 className={Styles.truncate}>{name}</Body2>
					<Body2>|</Body2>
					<Body2 className={Styles.truncate}>{displayRole}</Body2>
				</div>
				{includeQuickResponderBadge && (
					<div className={Styles.quickResponse}>
						<Icon name="timeline" size="sm" />
						Typically responds within 24h
					</div>
				)}
			</div>
		</div>
	);
};

interface TeamInfoProps {
	bio: Decorated['bio'];
}

const TeamInfo = ({ bio }: TeamInfoProps) => {
	const { description } = bio || {};

	return (
		<div>
			<H3 className={Styles.title}>Meet the team</H3>
			<VendorInfo bio={bio} />
			<div className={Styles.description}>{description}</div>
		</div>
	);
};

export default TeamInfo;
